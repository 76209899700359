<template>
  <div class="defalutPassword" v-loading="loading">
    <div class="defalutPasswordTitle">{{$t('lang.SText116')}}</div>
    <div class="password-main">
      <div class="show1" v-show="!editFlag">
        <div class="password" v-show="showPassword">
          <div class="passwordtext">{{ password }}</div>
          <svg-icon @click="showPassword = false" icon-class="eye"></svg-icon>
        </div>
        <div class="password" v-show="!showPassword">
          <div class="passwordtext">{{ hidePassword }}</div>
          <svg-icon
            icon-class="hideEye"
            @click="showPassword = true"
          ></svg-icon>
        </div>
        <el-button
          style="margin-top: 30px;width: 360px"
          class="change"
          type="primary"
          @click="editFlag = true"
          >{{$t('lang.SText117')}}</el-button
        >
      </div>

      <div class="show2" v-show="editFlag">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item
            class="form-item"
            prop="pwd"
            :label="$t('lang.NText321')"
          >
            <el-input v-model="form.pwd" >
            </el-input> </el-form-item
        ></el-form>
        <div class="bottomButton">
          <el-button class="saveButton passwordButton" @click="SavePassword"
            >{{$t('lang.SText13')}}</el-button
          ><el-button
            class="infoButton passwordButton"
            @click="editFlag = false"
            >{{$t('lang.QText79')}}</el-button
          >
        </div>
      </div>
    </div>
    <div class="text">
      * {{$t('lang.SText118')}}
      {{$t('lang.SText119')}}
    </div>
  </div>
</template>

<script>
import cryptoJs from "crypto-js";
const encryptDes = (message, key) => {
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var encrypted = cryptoJs.DES.encrypt(message, keyHex, {
    mode: cryptoJs.mode.ECB,
    padding: cryptoJs.pad.Pkcs7,
  });
  return encrypted.toString();
};

const decryptDes = (message, key) => {
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var decrypted = cryptoJs.DES.decrypt(
    {
      ciphertext: cryptoJs.enc.Base64.parse(message),
    },
    keyHex,
    {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7,
    }
  );
  return decrypted.toString(cryptoJs.enc.Utf8);
};
export default {
  data() {
    var checkPassword = (rule, value, cb) => {
      const regIdCard = /^(?=.*[a-z]+)(?=.*?[A-Z])(?=.*[0-9]+)[a-zA-Z0-9].{8,18}$/;
      if (!regIdCard.test(value)) {
        return cb(
          new Error(
            this.$t('lang.SText187')
          )
        );
      } else {
        cb();
      }
    };
    return {
      loading: false,
      password: null,
      hidePassword: null,
      form: {},
      editFlag: false,
      showPassword: false,
      rules: {
        pwd: [
          {
            required: true,
            message: this.$t('lang.SText188'),
          },
          {
            validator: checkPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getDefaultPassword();
    // this.$session("userInfo").get((value) => {
    //   this.userInfo = value;
    // });
  },
  methods: {
    getDefaultPassword() {
      let that = this;
      that.$ajax.cPost(that.source.staff_api.show).then((res) => {
        this.loading = false;
        if (res.data.result) {
          let data1 = res.data.data;
          let key = "vip";
          this.password = decryptDes(data1, key);
          this.hidePassword =
            this.password.slice(0, 2) +
            "******" +
            this.password.slice(this.password.length - 2, this.password.length);
        } else {
          this.$notify.error({
            title: res.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    SavePassword() {
      let that = this;
      let key = "vip";
      let data = encryptDes(that.form.pwd, key);
      this.$refs.form.validate((valid) => {
        if (valid) {
          that.loading = true;
          that.$ajax
            .cPost(that.source.staff_api.password, { pwd: data })
            .then((res) => {
              that.loading = false;
              if (res.data.result) {
                that.$notify.success({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
                that.editFlag = false;
                that.showPassword = false;
                that.form.pwd = "";
                that.getDefaultPassword();
              } else {
                that.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.defalutPassword {
  width: 900px;
  margin-left: 150px;
  .defalutPasswordTitle {
    font-size: 30px;
    color: $mainColor;
    text-align: left;
  }
  .password-main {
    width: 100%;
    margin: 100px auto;
    .password {
      margin: auto;
      color: $background2;
      height: 122px;
      line-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
    }
    .svg-icon {
      height: 30px;
      width: 30px;
      padding: 20px;
    }
    .change {
      width: 360px;
      font-size: 20px;
    }
    .show2{
        width: 360px;
        margin: auto;
    }
  }
  .text {
    font-size: 14px;
    color: #9b9b9b;
    text-align: left;
    width: 485px;
    line-height: 18px;
  }
  .passwordButton,
  .passwordButton:focus {
    width: 170px !important;
    font-size: 20px;
    padding: 0;
    flex: 1;
    height: 64px;
    border-width: 2px;
    border-color: $mainColor;
    border-radius: 0;
    text-align: center;
    font-family: "montserratregular";
  }
  .saveButton {
    background: $mainColor;
    color: #fff;
  }
  .saveButton:hover {
    background: $background2;
    border-color: $background2;
  }
  .infoButton {
    color: $mainColor;
    background-color: #fff;
  }
  .infoButton:hover {
    background: $background2;
    border-color: $background2;
    color: #fff;
  }
  .bottomButton {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}
</style>